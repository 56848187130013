@font-face {
  font-family: 'Prompt-Regular';
  src: url('resources/fonts/Prompt-Regular.ttf');
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('resources/fonts/Prompt-Bold.ttf');
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('resources/fonts/Prompt-Medium.ttf');
}

body {
  font-weight: 400;
  font-family: 'Prompt-Regular';
  background-color: #383838;
}

.container {
  background-color: #fff
}

.nikkei-index .nikkei-header {
  background-color: #005790 !important;
  color: white;
}

.nikkei-index .nav .navbar-brand .navbar-brand-subtop {
  font-size: 12px;
  color: #be0000;
}

.nikkei-index .nav .navbar-brand,
.nikkei-index .nav {
  align-items: center;
}

.nikkei-index .nav .navbar-brand .navbar-brand-top {
  font-size: 16px;
  margin-right: 15px;
  min-width: 120px;
  padding-left: 10px;
}

.nikkei-index .nav .navbar-right {
  flex: 1;
  text-align: right;
}

.nikkei-index .nav .navbar-right ul {
  display: flex;
  justify-content: flex-end;
}

.nikkei-index .nav .navbar-right ul li.members-link {
  list-style: none;
  margin-left: 40px;
  min-width: 140px;
  text-align: center;
  background-color: #f0f0f0;
  margin-right: 5px;
}

.nikkei-index .nav .navbar-right ul li.language-link {
  list-style: none;
}

.nikkei-index .nav .navbar-right ul li.language-link a {
  color: #337ab7;
  padding: 5px 10px;
  font-size: 12px;
  text-decoration: none;
}

.nikkei-index .nav .navbar-right ul li.active a {
  color: #fff;
  background-color: #005790;
}

.nikkei-index .nav .navbar-right ul li.language-link a:hover {
  color: #fff;
  background-color: #005790;
  text-decoration: none
}

.nikkei-index .nikkei-content {
  color: #fff;
  background-color: #005790;
  padding: 30px 10px;
}

.nikkei-index .nikkei-content .l-panel {
  border-left: 2px solid #fff;
  padding-left: 10px;
}

.nikkei-index .nikkei-content .l-panel .top-nk225-text-nk,
.nikkei-index .nikkei-content .l-panel .top-nk225-text {
  font-size: 22px;
}

.nikkei-index .nikkei-content .l-panel .top-nk225-text-link a {
  color: #fff;
}

.align-baseline {
  vertical-align: baseline !important;
}

.nikkei-index .nikkei-content .r-panel {
  text-align: right;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.nikkei-index .nikkei-content .r-panel .top-nk225-menu {
  text-align: right;
  font-size: 16px;
}

.nikkei-index .nikkei-content .r-panel .top-nk225-menu a {
  color: #fff;
}

.nikkei-index .nikkei-content .c-panel {
  padding: 10px 0;
}

.nikkei-index .nikkei-content .nikkei-today .table>tbody>tr>td {
  padding: 5px;
  text-align: center;
  color: #fff;
}

.nikkei-stat {
  padding: 10px;
}

.nikkei-stat h4 {
  color: #005790
}

.nikkei-stat .table tr td,
.nikkei-stat .table tr th {
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #005790;
  color: #005790
}

.nikkei-footer {
  margin-top: 10px;
  padding: 10px 0;
  color: #fff;
  background-color: #005790;
  text-align: center;
}

.nikkei-index .nikkei-header img {
  width: 100%;
  height: auto;
  min-width: 600px;
}